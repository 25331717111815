{
  "common" : {
    "round_num" : "Round number",
    "unloading" : "Unloading",
    "receipt_of_goods": "Receipt of goods",
    "storing" : "Storing",
    "storage" : "Storage",
    "removal" : "Shipping",
    "outgoing_goods_control" : "Outgoing goods inspection",
    "loading" : "Loading",
    "article_name" : "Article name",
    "article_number" : "Article number",
    "cancel" : "Cancel",
    "yes" : "Yes",
    "no" : "No",
    "accept" : "Accept",
    "no_entry" : "No entries.",
    "ok" : "OK",
    "back" : "BACK"
  },
  "load_game" : {
    "load_game" : "Load Game",
    "game_name" : "Game name",
    "game_round" : "Game round",
    "balance" : "Balance",
    "created_at" : "Created at",
    "last_action" : "Last changed"
  },
  "login" : {
    "username" : "User name",
    "password" : "Password"
  },
  "menu" : {
    "title" : "FLW Businessgame",
    "new_game" : "New Game",
    "game_name" : "Game name",
    "load_game" : "Load Game",
    "logout" : "Logout"
  },
  "side_menu" : {
    "home" : "Home",
    "finance" : "Finance",
    "key_figures" : "Key figures",
    "orderOverview": "Ordering",
    "exhaust" : "Exhaust",
    "break_disk" : "Brake discs",
    "taillight" : "Rear lights",
    "shock_absorber" : "Shock absorber",
    "human_resource" : "Human Resources",
    "job_market" : "Job market",
    "conveyor" : "Conveyor",
    "conveyor_market" : "Conveyor market",
    "information_technology" : "IT",
    "organization" : "Organisation",
    "next_round" : "Next round",
    "prev_round" : "Previous round",
    "round_num" : "Round | Rounds",
    "balance": "Balance",
    "customer_satisfaction" : "Customer satisfaction",
    "main_menu" : "Main menu",
    "dia_next_round" : "Next round",
    "dia_next_round_info" : "Do you really want to start the next round?",
    "dia_prev_round" : "Previous Round",
    "dia_prev_round_info" : "Do you really want to recreate the last round?",
    "dia_error" : "Error",
    "dia_error_info" : "An error occurred while loading the next round. Please return to the main menu and reload the game."
  },
  "dashboard" : {
    "message" : "Message | Messages",
    "transport_overview" : "Pallet transport overview",
    "transported_pallets" : "Processed pallets",
    "not_transported_pallets" : "Unprocessed pallets"
  },
  "finance" : {
    "finance" : "Finance",
    "starting_balance" : "Start balance",
    "orders" : "Ordering Department",
    "income_sales" : "Deposit from sale of goods",
    "expenses_sales" : "Disbursement for purchases of goods",
    "expenses_storage" : "Disbursement for warehousing",
    "human_resource" : "Personnel department",
    "expenses_salaries" : "Wage payments incl. overtime",
    "expenses_training" : "Disbursements for qualification measures",
    "expenses_personnel" : "Disbursement for personnel recruitment/dismissal",
    "expenses_work_climate" : "Disbursements in the working atmosphere",
    "technology" : "Technology Department",
    "expenses_it" : "Disbursement for IT",
    "income_conveyor_sales" : "Income from the sale of conveyor",
    "expenses_maintenance" : "Disbursement for maintenance, repair and overhaul",
    "expenses_conveyor_purchase" : "Disbursement for the purchase of conveyor",
    "organization" : "Organisation Department",
    "expenses_loading_sec" : "Disbursement for load unit protection",
    "expenses_abc" : "Disbursement for ABC analysis/zoning",
    "interest" : "Interest",
    "end_balance" : "End balance"
  },
  "keyfigures": {
    "keyfigures" : "Key figures",
    "prev_round" : "Previous round",
    "current_round" : "Current round",
    "num_emp_wfp" : "Number of employees with forklift licence",
    "num_emp_wofp" : "Number of employees without forklift licence",
    "num_emp" : "Total number of employees",
    "workload_emp" : "Employee utilisation in %",
    "num_conveyor_wfp" : "Number of conveyors (forklift licence required)",
    "num_conveyor_wofp" : "Number of conveyors (forklift licence not required)",
    "num_conveyor" : "Total number of conveyor",
    "workload_conveyor" : "Conveyor utilisation in%",
    "num_overtime" : "Number of overtime hours",
    "num_transportable_pallets" : "Number of pallets to be processed",
    "num_transported_pallets" : "Number of processed pallets"
  },
  "order": {
    "dia_cancel" : "Cancel order",
    "dia_cancel_info" : "Do you want to cancel this order for {quantity} pallet(s) of item {articleName}?",
    "dia_cancel_cost" : "The cost is therefore {money}.",
    "order_view" : "Ordering",
    "current_stock" : "Current stock",
    "open_orders" : "Open orders",
    "outstanding_customer_jobs" : "Backlog of customer orders",
    "customer_jobs" : "Customer orders in this round",
    "in_stock" : "In storage",
    "stock_value" : "Stock value",
    "order_round" : "Ordering round",
    "pallet" : "Pallet | Pallets",
    "delivery_round" : "Delivery date",
    "order" : "Order",
    "order_quantity" : "Order quantity",
    "delivery_costs_per_pallet" : "Delivery costs per pallet",
    "product_cost" : "Product costs",
    "total_costs" : "Total costs",
    "order_now" : "Order",
    "order_form" : "Order form",
    "normal_delivery" : "Standard delivery",
    "express_delivery" : "Express delivery"
  },
  "article" : {
    "current_stock" : "Number of pallets in the warehouse",
    "information" : "Information",
    "order_information" : "Order information",
    "open_orders" : "Open orders",
    "currently_in_stock" : "Currently in storage",
    "mean_consumption" : "Average consumption",
    "range" : "Coverage",
    "stock_value" : "Stock value",
    "purchase_price" : "Purchase price",
    "min_order_quantity" : "Minimum order quantity",
    "order_fix_costs" : "Fixed order costs",
    "sales_price" : "Sales price",
    "discount_level" : "Discount level",
    "min_discount_quantity" : "as of order quantity",
    "pallet_costs" : "Cost per pallet",
    "delivery_type" : "Delivery method",
    "delivery_time" : "Delivery time",
    "delivery_costs" : "Delivery costs",
    "classification" : "Classification",
    "cancel" : "Cancel",
    "delivery_time_rounds": "{count} round | {count} rounds"
  },
  "human_resource" : {
    "human_resource" : "Human Resources",
    "list_of_emp" : "List of all employees",
    "in_process" : "In progress",
    "train" : "Train",
    "fire" : "Terminate",
    "work_time" : "Working hours",
    "emp_qualification" : "Employee qualification",
    "work_climate" : "Work climate",
    "work_climate_invest" : "Investment in the work climate",
    "future_emp" : "List of all future employees",
    "part_time" : "Part-time",
    "full_time" : "Full-time",
    "temp_time" : "Temporary workers",
    "unknown" : "Unknown",
    "forklift_permit" : "Forklift licence",
    "security_training" : "Safety training",
    "quality_management_sem" : "QM seminar",
    "name" : "Name",
    "contract_type" : "Contract",
    "contract_as_of" : "In the company as of round",
    "contract_till" : "In the company until round",
    "process" : "Field of application",
    "salary" : "Salary",
    "motivation" : "Motivation in %",
    "capacity" : "Utilisation",
    "crash_delay" : "Accident delay",
    "overtime" : "Overtime",
    "num_emp" : "Number of employees",
    "num_wfp" : "# Forklift licence",
    "num_sec" : "# Safety training",
    "num_qm" : "# QM",
    "dia_fire" : "Do you really want to dismiss {empName}?",
    "dia_fire_info" : "{empName} leaves your company in round {roundNum}.",
    "dia_fire_cost" : "The severance payment amounts to: {money}",
    "dia_training" : "Implementation of a qualification measure",
    "dia_training_info" : "Herewith {empName} is registered for the qualification measure {qualName} at the agreed price of {money}.",
    "dia_training_duration" : "The training duration is {duration}",
    "dia_hire" : "Hire {empName}",
    "dia_hire_info" : "Do you really want to set {empName} as {contractType}?",
    "dia_hire_salary" : "The salary per round is: {money}.",
    "dia_hire_cost" : "The one-off recruitment costs are: {money}.",
    "dia_hire_start" : "The employee is available from round: {roundNum}.",
    "job_market" : "Job market",
    "hire" : "Hire"
  },
  "conveyor" : {
    "dia_maintenance" : "Agreement maintenance contract",
    "dia_maintenance_info" : "Do you really want to {actionName} the maintenance contract for {conveyorName}?",
    "dia_maintenance_cost" : "{maintenanceName} per round is: {money}.",
    "dia_overhaul" : "General overhaul implementation",
    "dia_overhaul_info" : "Do you really want to overhaul {conveyorName}?",
    "dia_overhaul_cost" : "The costs amount to one-time: {money}.",
    "conveyor" : "Conveyor",
    "current_conveyor" : "Currently available conveyor",
    "sold" : "Sold",
    "conclude" : "Accept | accept",
    "fire": "Cancel | cancel",
    "overhaul" : "General overhaul",
    "future_conveyor" : "Future available conveyor",
    "running_cost" : "Ongoing costs",
    "capacity" : "Utilisation",
    "needs_forklift_permit" : "Requires forklift licence",
    "price" : "The price",
    "saving" : "The saving",
    "name" : "Name",
    "condition" : "Condition in %",
    "bought_in_round" : "Purchased in round",
    "process" : "Field of application",
    "maintenance" : "Maintenance",
    "num_conveyor" : "Number of conveyor",
    "crash_delay" : "Accident delay",
    "repair_cost" : "Repair costs",
    "overhaul_cost" : "General overhaul costs",
    "repair_duration" : "Repair time (in min)",
    "maintenance_cost" : "Maintenance costs",
    "ordered_in_round" : "Ordered in round",
    "delivery_round" : "Delivery in round",
    "speed" : "Speed",
    "dia_sell" : "Sell conveyor",
    "dia_sell_info" : "Do you really want to sell {conveyorName}?",
    "dia_sell_profit" : "The proceeds are: {money}.",
    "dia_buy" : "Buy conveyor",
    "dia_buy_info" : "Do you really want to buy {conveyorName}?",
    "dia_buy_cost": "The price is: {money}.",
    "conveyor_market" : "Conveyor market",
    "delivery_duration" : "Delivery time",
    "buy" : "Buy",
    "sell" : "Sell",
    "value" : "Value",
    "information" : "Informationen",
    "conveyor_name_3de": "3 wheel electric forklift",
    "conveyor_name_4ddf": "4 wheel diesel forklift (with filter)",
    "conveyor_name_4dd": "4 wheel diesel forklift (without filter)",
    "conveyor_name_4de": "4 wheel electric forklift",
    "conveyor_name_4dg": "4 wheel gas forklift",
    "conveyor_name_eh": "Electric hand pallet truck",
    "conveyor_name_gh": "Walking high-lift truck",
    "conveyor_name_hh": "Hand pallet truck"
  },
  "company": {
    "organization" : "Organisation",
    "dia_information": "To be able to realise this form of organisation, you must conclude a contract.  <br><br> The cost is {money} per round. <br><br> The selection of another form of organisation is then possible as follows: <br><br> Basic state in {durationBasic} rounds. <br><br> Computerised storage system in {durationL1} rounds. <br><br> Forklift control system batch in {durationL2} rounds. <br><br>",
    "information_technology" : "IT",
    "current_system" : "Current warehouse system",
    "no_change_possible" : "System currently under reconstruction. Change not possible!",
    "round_cost" : "Cost per round: {money}",
    "additional_modules" : "Additional modules",
    "loading_equipment_condition" : "Condition of the loading equipment",
    "leConditions_1" : "70 % - 0 € per round",
    "leConditions_2" : "80 % - 450 € per round",
    "leConditions_3" : "95 % - 800 € per round",
    "technology_1" : "Basic state",
    "technology_2" : "Computerised warehouse",
    "technology_3" : "Forklift control system Batch",
    "technology_4" : "Forklift control system Radio",
    "service_1" : "Optimum order quantity - 450 €",
    "service_2" : "Notification of reorder point - 200 €",
    "service_3" : "Safety stock notification - 200 €",
    "service_4" : "Status report - 500 €",
    "service_5" : "Stock overview - 300 €",
    "dia_abc" : "Information",
    "dia_abc_info" : "To all EDP staff: <br><br> We hereby request that you immediately carry out an ABC analysis in the warehouse, as we may wish to re-zone it. <br><br>        We expect the results in the next round. <br><br> The price is {money}. <br><br>",
    "dia_abc_result" : "ABC - Analysis Result",
    "dia_zoning" : "Information",
    "dia_zoning_info" : " To all staff:  <br><br>  After conducting an ABC analysis, we decided to rezone the warehouse.  <br><br>  We therefore ask for your active support in the coming days. Thank you very much.  <br><br>  The price is {money}. The duration is 1 round. <br><br>",
    "goods_receipt" : "Incoming goods",
    "control_level_1" : "No control",
    "control_level_2" : "Every tenth pallet",
    "control_level_3" : "Every fifth pallet",
    "control_level_4" : "Every second pallet",
    "control_level_5" : "Every pallet",
    "control_time" : "Control times per pallet",
    "control_time_incoming" : "40 Seconds  Fix <br> 150 Seconds  variable",
    "control_time_outgoing" : "20 Seconds  Fix <br> 100 Seconds  variable",
    "loading_equipment_prot" : "Load unit securing",
    "le_protection_1" : "Every pallet",
    "le_protection_2" : "No securing",
    "le_cost" : "Cost per pallet: {money} <br> Time for securing per pallet: 50 seconds",
    "storage" : "Storage",
    "strategy_for_storing" : "Strategy for storage",
    "storage_strategy_1": "arbitrary",
    "storage_strategy_2":"path-optimised",
    "strategy_for_allocation" : "Strategy for storage space allocation",
    "strategy_allocation_1" : "arbitrary",
    "strategy_allocation_2" : "Fixed space",
    "strategy_allocation_3" : "ABC zoning",
    "abc_analysis" : "Initiate ABC analysis",
    "abc_results" : "Result of the ABC analysis",
    "abc_zoning" : "Initiate ABC zoning",
    "strategy_for_picking" : "Strategy for picking",
    "strategy_picking_1": "Fifo",
    "strategy_picking_2": "Lifo",
    "strategy_picking_3": "path-optimised",
    "strategy_picking_4": "arbitrary",
    "capacity_distribution" : "Capacity distribution warehouse",
    "storing" : "Storage",
    "removal" : "Picking",
    "outgoing_goods" : "Shipping",
    "product_control" : "Product inspection"
  }
}