{
  "common" : {
    "round_num" : "Rundennummer",
    "unloading" : "Entladung",
    "receipt_of_goods": "Warenvereinnahmung",
    "storing" : "Einlagerung",
    "storage" : "Lager",
    "removal" : "Auslagerung",
    "outgoing_goods_control" : "Warenausgangskontrolle",
    "loading" : "Verladung",
    "article_name" : "Artikelname",
    "article_number" : "Artikelnummer",
    "cancel" : "Abbrechen",
    "yes" : "Ja",
    "no" : "Nein",
    "accept" : "Bestätigen",
    "no_entry" : "Keine Einträge vorhanden.",
    "ok" : "OK",
    "back" : "Zurück"
  },
  "load_game" : {
    "load_game" : "Spiel laden",
    "game_name" : "Name des Spiels",
    "game_round" : "Spielrunde",
    "balance" : "Kontostand",
    "created_at" : "Erstelldatum",
    "last_action" : "Letzte Aktion"
  },
  "login" : {
    "username" : "Benutzername",
    "password" : "Passwort"
  },
  "menu" : {
    "title" : "FLW Planspiel",
    "new_game" : "Neues Spiel",
    "game_name" : "Spielname",
    "load_game" : "Spiel laden",
    "logout" : "Ausloggen"
  },
  "side_menu" : {
    "home" : "Home",
    "finance" : "Finanzen",
    "key_figures" : "Kennzahlen",
    "orderOverview": "Bestellwesen",
    "exhaust" : "Auspuff",
    "break_disk" : "Bremsscheiben",
    "taillight" : "Rückleuchten",
    "shock_absorber" : "Stossdämpfer",
    "human_resource" : "Personalwesen",
    "job_market" : "Stellenmarkt",
    "conveyor" : "Fördermittel",
    "conveyor_market" : "Fördermittelmarkt",
    "information_technology" : "Informationstechnik",
    "organization" : "Organisation",
    "next_round" : "Nächste Runde",
    "prev_round" : "Runde zurück",
    "round_num" : "Runde | Runden",
    "balance": "Kontostand",
    "customer_satisfaction" : "Kundenzufriedenheit",
    "main_menu" : "Hauptmenü",
    "dia_next_round" : "Nächste Runde",
    "dia_next_round_info" : "Wollen Sie wirklich die nächste Runde starten?",
    "dia_prev_round" : "Vorherige Runde",
    "dia_prev_round_info" : "Wollen Sie wirklich die letzte Runde wiederherstellen?",
    "dia_error" : "Fehler",
    "dia_error_info" : "Beim Laden der nächsten Rund ist ein Fehler aufgetreten. Bitte kehren Sie zum Hauptmenü zurück und laden Sie das Spiel neu."
  },
  "dashboard" : {
    "message" : "Nachricht | Nachrichten",
    "transport_overview" : "Palettentransport Übersicht",
    "transported_pallets" : "Bearbeitete Paletten",
    "not_transported_pallets" : "Nicht bearbeitete Paletten"
  },
  "finance" : {
    "finance" : "Finanzen",
    "starting_balance" : "Kontostand Beginn Runde",
    "orders" : "Bereich Bestellmenge",
    "income_sales" : "Einzahlung aus Warenverkauf",
    "expenses_sales" : "Auszahlung für Wareneinkäufe",
    "expenses_storage" : "Auszahlung für Lagerhaltung",
    "human_resource" : "Bereich Personal",
    "expenses_salaries" : "Lohnzahlungen inkl. Überstunden",
    "expenses_training" : "Auszahlungen für Qualifizierungsmaßnahmen",
    "expenses_personnel" : "Auszahlung für Personalbeschaffung/-entlassung",
    "expenses_work_climate" : "Auszahlungen in das Betriebsklima",
    "technology" : "Bereich Technik",
    "expenses_it" : "IT-Kosten",
    "income_conveyor_sales" : "Einnahmen aus Fördermittelverkauf",
    "expenses_maintenance" : "Kosten für Wartung, Reparatur, und Überholung",
    "expenses_conveyor_purchase" : "Kosten für Fördermitteleinkauf",
    "organization" : "Bereich Organisation",
    "expenses_loading_sec" : "Auszahlung für Ladeeinheitensicherung",
    "expenses_abc" : "Auszahlung für ABC-Analyse/Zonung",
    "interest" : "Zinsen",
    "end_balance" : "Kontostand Ende Runde"
  },
  "keyfigures": {
    "keyfigures" : "Kennzahlen",
    "prev_round" : "Vorrunde",
    "current_round" : "Aktuelle Runde",
    "num_emp_wfp" : "Anzahl Mitarbeiter mit Staplerschein",
    "num_emp_wofp" : "Anzahl Mitarbeiter ohne Staplerschein",
    "num_emp" : "Anzahl Mitarbeiter gesamt",
    "workload_emp" : "Mitarbeiterauslastung in %",
    "num_conveyor_wfp" : "Anzahl Fördermittel (Staplerschein erforderlich)",
    "num_conveyor_wofp" : "Anzahl Fördermittel (Staplerschein nicht erforderlich)",
    "num_conveyor" : "Anzahl Fördermittel gesamt",
    "workload_conveyor" : "Fördermittelauslastung in %",
    "num_overtime" : "Anzahl Überstunden",
    "num_transportable_pallets" : "Anzahl zu bearbeitender Paletten",
    "num_transported_pallets" : "Anzahl bearbeiteter Paletten"
  },
  "order": {
    "dia_cancel" : "Bestellung stornieren",
    "dia_cancel_info" : "Wollen Sie diese Bestellung über {quantity} Pallete(n) des Artikels {articleName} stornieren?",
    "dia_cancel_cost" : "Die Kosten belaufen sich somit auf {money}.",
    "order_view" : "Bestellwesen",
    "current_stock" : "Aktueller Lagerbestand",
    "open_orders" : "Offene Bestellungen",
    "outstanding_customer_jobs" : "Rückständige Kundenaufträge",
    "customer_jobs" : "Kundenaufträge in dieser Runde",
    "in_stock" : "Im Lager",
    "stock_value" : "Bestandswert",
    "order_round" : "Bestellrunde",
    "pallet" : "Palette | Paletten",
    "delivery_round" : "Liefertermin",
    "order" : "Bestellung",
    "order_quantity" : "Bestellmenge",
    "delivery_costs_per_pallet" : "Lieferkosten pro Palette",
    "product_cost" : "Produktkosten",
    "total_costs" : "Gesamtkosten",
    "order_now" : "Bestellen",
    "order_form" : "Bestellformular",
    "normal_delivery" : "Normallieferung",
    "express_delivery" : "Eillieferung"
  },
  "article" : {
    "current_stock" : "Anzahl Paletten im Lager",
    "information" : "Information",
    "order_information" : "Bestellinformationen",
    "open_orders" : "Offene Bestellungen",
    "currently_in_stock" : "Aktuell im Lager",
    "mean_consumption" : "Durchschnittsverbrauch",
    "range" : "Reichweite",
    "stock_value" : "Bestandswert",
    "purchase_price" : "Einkaufspreis",
    "min_order_quantity" : "Mindestbestellmenge",
    "order_fix_costs" : "Fixe Bestellkosten",
    "sales_price" : "Verkaufspreis",
    "discount_level" : "Rabattstufe",
    "min_discount_quantity" : "ab Bestellmenge",
    "pallet_costs" : "Kosten pro Palette",
    "delivery_type" : "Lieferart",
    "delivery_time" : "Lieferdauer",
    "delivery_costs" : "Lieferkosten",
    "classification" : "Klassifizierung",
    "cancel" : "Stornieren",
    "delivery_time_rounds": "{count} Runde | {count} Runden"
  },
  "human_resource" : {
    "human_resource" : "Personalwesen",
    "list_of_emp" : "Liste aller Mitarbeiter",
    "in_process" : "In Bearbeitung",
    "train" : "Schulen",
    "fire" : "Kündigen",
    "work_time" : "Arbeitszeit",
    "emp_qualification" : "Mitarbeiter Qualifikation",
    "work_climate" : "Betriebsklima",
    "work_climate_invest" : "Investition in das Betriebsklima",
    "future_emp" : "Liste aller zukünftigen Mitarbeiter",
    "part_time" : "Teilzeit",
    "full_time" : "Vollzeit",
    "temp_time" : "Leiharbeiter",
    "unknown" : "Unbekannt",
    "forklift_permit" : "Staplerschein",
    "security_training" : "Sicherheitstraining",
    "quality_management_sem" : "QM-Seminar",
    "name" : "Name",
    "contract_type" : "Beschäftigung",
    "contract_as_of" : "Im Unternehmen ab Runde",
    "contract_till" : "Im Unternehmen bis Runde",
    "process" : "Einsatzgebiet",
    "salary" : "Gehalt",
    "motivation" : "Motivation in %",
    "capacity" : "Auslastung",
    "crash_delay" : "Unfallverzögerung",
    "overtime" : "Überstunden",
    "num_emp" : "Anzahl Mitarbeiter",
    "num_wfp" : "# Staplersch.",
    "num_sec" : "# Sicherheitst.",
    "num_qm" : "# QM",
    "dia_fire" : "Wollen Sie {empName} wirklich entlassen?",
    "dia_fire_info" : "{empName} scheidet in Runde {roundNum} aus Ihrem Unternehmen aus.",
    "dia_fire_cost" : "Die Abfindung beträgt: {money}",
    "dia_training" : "Durchführung einer Qualifizierungsmaßnahme",
    "dia_training_info" : "Hiermit wird {empName} zur Qualifizierungsmaßnahme {qualName} zum vereinbarten Preis von {money} angemeldet.",
    "dia_training_duration" : "Die Schulungsdauer beträgt {duration}",
    "dia_hire" : "{empName} einstellen",
    "dia_hire_info" : "Wollen Sie {empName} wirklich als {contractType} einstellen?",
    "dia_hire_salary" : "Das Gehalt pro Runde beträgt: {money}.",
    "dia_hire_cost" : "Die einmaligen Einstellungskosten betragen: {money}.",
    "dia_hire_start" : "Der Angestellte ist verfügbar ab Runde: {roundNum}.",
    "job_market" : "Stellenmarkt",
    "hire" : "Einstellen"
  },
  "conveyor" : {
    "dia_maintenance" : "Wartungsvertrag abschließen",
    "dia_maintenance_info" : "Wollen Sie den Wartungsvertrag für {conveyorName} wirklich {actionName}?",
    "dia_maintenance_cost" : "{maintenanceName} pro Runde beträgt: {money}.",
    "dia_overhaul" : "Generalüberholung durchführung",
    "dia_overhaul_info" : "Wollen Sie {conveyorName} wirklich generalüberholen?",
    "dia_overhaul_cost" : "Die Kosten belaufen sich auf einmalig: {money}.",
    "conveyor" : "Fördermittel",
    "current_conveyor" : "Aktuell verfügbare Fördermittel",
    "sold" : "Verkauft",
    "conclude" : "Abschließen | abschließen",
    "fire": "Kündigen | kündigen",
    "overhaul" : "Generalüberholung",
    "future_conveyor" : "Zukünftig verfügbare Fördermittel",
    "running_cost" : "Laufende Kosten",
    "capacity" : "Auslastung",
    "needs_forklift_permit" : "Benötigt Staplerschein",
    "price" : "Der Preis",
    "saving" : "Die Einsparung",
    "name" : "Name",
    "condition" : "Zustand in %",
    "bought_in_round" : "Gekauft in Runde",
    "process" : "Einsatzgebiet",
    "maintenance" : "Wartung",
    "num_conveyor" : "Anzahl Fördermittel",
    "crash_delay" : "Unfallverzögerung",
    "repair_cost" : "Reparaturkosten",
    "overhaul_cost" : "Generalüberholungskosten",
    "repair_duration" : "Reparaturdauer (in min)",
    "maintenance_cost" : "Wartungskosten",
    "ordered_in_round" : "Bestellt in Runde",
    "delivery_round" : "Lieferzeit in Runde",
    "speed" : "Geschwindigkeit",
    "dia_sell" : "Fördermittel verkaufen",
    "dia_sell_info" : "Wollen Sie {conveyorName} wirklich verkaufen?",
    "dia_sell_profit" : "Der Erlös beträgt: {money}.",
    "dia_buy" : "Fördermittel kaufen",
    "dia_buy_info" : "Wollen Sie {conveyorName} wirklich kaufen?",
    "dia_buy_cost": "Der Preis beträgt: {money}.",
    "conveyor_market" : "Fördermittelmarkt",
    "delivery_duration" : "Lieferdauer",
    "buy" : "Kaufen",
    "sell" : "Verkaufen",
    "value" : "Wert",
    "information" : "Informationen",
    "conveyor_name_3de": "3 Rad Elektro-Gablestapler",
    "conveyor_name_4ddf": "4 Rad Diesel-Gabelstapler (mit Filter)",
    "conveyor_name_4dd": "4 Rad Diesel-Gabelstapler (ohne Filter)",
    "conveyor_name_4de": "4 Rad Elektro-Gabelstapler",
    "conveyor_name_4dg": "4 Rad Gas-Gabelstapler",
    "conveyor_name_eh": "Elektrohandhubwagen",
    "conveyor_name_gh": "Geh-Hochhubwagen",
    "conveyor_name_hh": "Handgabelhubwagen"
  },
  "company": {
    "organization" : "Organisation",
    "dia_information": "Um diese Organisationsform realisieren zu können, müssen Sie einen Vertrag abschließen. <br><br> Die Kosten belaufen sich auf {money} pro Runde. <br><br> Die Auswahl einer anderen Organisationsform ist dann wie folgt möglich: <br><br> Grundzustand in {durationBasic} Runden. <br><br> Rechnergestütztes Lagersystem in {durationL1} Runden. <br><br> Staplerleitsystem Batch in {durationL2} Runden.<br><br>",
    "information_technology" : "Informationstechnik",
    "current_system" : "Aktuelles Lagersystem",
    "no_change_possible" : "System aktuell im Umbau. Wechsel nicht möglich!",
    "round_cost" : "Kosten pro Runde: {money}",
    "additional_modules" : "Zusätzliche Module",
    "loading_equipment_condition" : "Zustand der Ladehilfsmittel",
    "leConditions_1" : "70 % - 0 € pro Runde",
    "leConditions_2" : "80 % - 450 € pro Runde",
    "leConditions_3" : "95 % - 800 € pro Runde",
    "technology_1" : "Grundzustand",
    "technology_2" : "Rechnergestütztes Lager",
    "technology_3" : "Staplerleitsystem Batch",
    "technology_4" : "Staplerleitsystem Funk",
    "service_1" : "Optimale Bestellmenge - 450 €",
    "service_2" : "Meldung Meldebestand - 200 €",
    "service_3" : "Meldung Sicherheitsbestand - 200 €",
    "service_4" : "Statusbericht - 500 €",
    "service_5" : "Lagerübersicht - 300 €",
    "dia_abc" : "Information",
    "dia_abc_info" : "An alle Mitarbeiter der EDV: <br><br> Hiermit bitten wir Sie, umgehend eine ABC-Analyse im Lager durchzuführen, da wir dieses eventuell neu zonen möchten. <br><br>        Die Ergebnisse erwarten wir in der nächsten Runde. <br><br> Der Preis beträgt {money}. <br><br>",
    "dia_abc_result" : "ABC - Analyse Ergebnis",
    "dia_zoning" : "Information",
    "dia_zoning_info" : "An alle Mitarbeiter: <br><br> Nach der Durchführung einer ABC-Analyse haben wir beschloßen, das Lager neu zu zonen.  <br><br> Wir bitten daher um ihre aktive Unterstützung in den nächsten Tagen. Vielen Dank. <br><br> Der Preis beträgt {money}. Die Dauer beträgt 1 Runde.<br><br>",
    "goods_receipt" : "Wareneingang",
    "control_level_1" : "Keine Kontrolle",
    "control_level_2" : "Jede zehnte Palette",
    "control_level_3" : "Jede fünfte Palette",
    "control_level_4" : "Jede zweite Palette",
    "control_level_5" : "Jede Palette",
    "control_time" : "Kontrollzeiten je Palette",
    "control_time_incoming" : "40 Sekunden Fix <br> 150 Sekunden variabel",
    "control_time_outgoing" : "20 Sekunden Fix <br> 100 Sekunden variabel",
    "loading_equipment_prot" : "Ladeeinheitensicherung",
    "le_protection_1" : "Bei jeder Palette",
    "le_protection_2" : "Bei keiner Palette",
    "le_cost" : "Kosten je Palette: {money} <br> Zeit für Sicherung je Palette: 50 Sekunden",
    "storage" : "Lager",
    "strategy_for_storing" : "Strategie für Einlagerung",
    "storage_strategy_1": "willkürlich",
    "storage_strategy_2":"wegoptimiert",
    "strategy_for_allocation" : "Strategie für Lagerplatzvergabe",
    "strategy_allocation_1" : "willkürlich",
    "strategy_allocation_2" : "Festplatz",
    "strategy_allocation_3" : "ABC-Zonung",
    "abc_analysis" : "ABC Analyse veranlassen",
    "abc_results" : "Ergebnis der ABC Analyse",
    "abc_zoning" : "ABC Zonung veranlassen",
    "strategy_for_picking" : "Strategie für Auslagerung",
    "strategy_picking_1": "Fifo",
    "strategy_picking_2": "Lifo",
    "strategy_picking_3": "wegoptimiert",
    "strategy_picking_4": "willkürlich",
    "capacity_distribution" : "Kapazitätsverteilung Lager",
    "storing" : "Einlagerung",
    "removal" : "Auslagerung",
    "outgoing_goods" : "Warenausgang",
    "product_control" : "Produktkontrolle"
  }
}